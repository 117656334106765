@font-face {
  font-family: "Cabinet Grotesk";
  font-style: normal;
  font-weight: 400;
  src: local("Cabinet Grotesk"),
    url("./assets/fonts/CabinetGrotesk-Regular.woff") format("woff");
}
@font-face {
  font-family: "Cabinet Grotesk";
  font-style: normal;
  font-weight: 500;
  src: local("Cabinet Grotesk"),
    url("./assets/fonts/CabinetGrotesk-Medium.woff") format("woff");
}
@font-face {
  font-family: "Cabinet Grotesk";
  font-style: normal;
  font-weight: 700;
  src: local("Cabinet Grotesk"),
    url("./assets/fonts/CabinetGrotesk-Bold.woff") format("woff");
}
@font-face {
  font-family: "ClashDisplay";
  src: url("./assets/fonts/ClashDisplay-Medium.woff2") format("woff2"),
    url("./assets/fonts/ClashDisplay-Medium.woff") format("woff"),
    url("./assets/fonts/ClashDisplay-Medium.ttf") format("truetype");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Photograph Signature";
  font-style: normal;
  font-weight: normal;
  src: local("Photograph Signature"),
    url("./assets/fonts/Photograph-Signature.woff") format("woff");
}
@font-face {
  font-family: "ASPHALTIC SCRATCH ROUNDED";
  font-style: normal;
  font-weight: normal;
  src: local("ASPHALTIC SCRATCH ROUNDED PERSO"),
    url("./assets/fonts/ASPHALTIC-SCRATCH-ROUNDED.woff") format("woff");
}

body {
  margin: 0;
  padding: 0;
  font-family: "Cabinet Grotesk";
  font-weight: 400;
  background-color: #020c15;
  color: #ffffff;
  overflow-x: hidden;
}
a {
  color: #00ffcc;
  text-decoration: none;
}
a:hover {
  color: #ff437f;
  text-decoration: underline;
}
/* Custom Scrollbar */
*::-webkit-scrollbar {
  width: 6px;
  height: 5px;
}
*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);
}
*::-webkit-scrollbar-thumb {
  background-color: #00ffcc;
}
.main_navbar {
  background-color: transparent !important;
  z-index: 100;
  transition: 0.2s all;
  padding: 15px 0;
}
img.brand_logo {
  width: 120px;
  margin-left: 30px;
}
.main_navbar.fixed_navbar {
  position: sticky;
  width: 100%;
  top: 0;
  background-color: #020c15 !important;
  box-shadow: 0 0 10px 5px rgb(4 4 4 / 50%);
  z-index: 1020;
  padding: 12px 0;
}
.navbar_right {
  display: flex;
  align-items: center;
}
.logout_btn {
  background: transparent;
  border: 2px solid transparent;
  color: #fff;
  font-size: 20px;
  margin-right: 15px;
  transition: 0.2s all;
  padding: 5px 20px;
  border-radius: 30px;
}
.logout_btn:hover {
  background: #021039;
  border: 2px solid #0b1c47;
}
.logout_btn img {
  margin-right: 10px;
}
.page_header {
  position: relative;
  padding: 0 0 10px;
  /* background: url("../src/assets/images/banner_bg.png") no-repeat; */
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  margin-top: -130px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.page_header .container-fluid {
  padding: 0 !important;
}
/* .page_header h1 {
  font-size: 58px;  
  color: #fff;  
  font-family: "ASPHALTIC SCRATCH ROUNDED";
  display: flex;
  align-items: center;
  justify-content: center;
}
.page_header h1 span {
  font-family: "Photograph Signature";  
  font-size: 150px; 
} */
.blue_txt {
  color: #00ffcc !important;
}
.orange_txt {
  color: #ff437f !important;
}
.header-profile .dropdown-menu {
  background-color: #020c15;
  box-shadow: 0 0 10px 5px rgb(4 4 4 / 50%);
  margin-top: 1.125rem !important;
  padding: 1.25rem 1.25rem;
  min-width: 270px;
  border: 0;
  right: 0;
  left: unset;
  z-index: -1;
}
.header-profile .dropdown-menu {
  /* display: block !important; */
  top: 100% !important;
  transform: translateY(20px) !important;
  transition: all 0.5s;
  opacity: 0;
}
.header-profile .dropdown-menu.show {
  transform: translateY(0px) !important;
  opacity: 1;
  z-index: 9;
}
.header-profile .dropdown-menu .dropdown-header {
  padding: 0 0 1rem;
  position: relative;
}
.header-profile .dropdown-menu .dropdown-header:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  height: 1px;
  width: 100%;
  background: rgb(0 255 204 / 30%);
}
.header-profile .dropdown-menu .dropdown-header span {
  color: #eee;
  display: flex;
  align-items: center;
}
.header-profile .dropdown-menu .dropdown-header span i {
  margin-right: 8px;
  color: #eee;
  transform: scale(1.2);
}
.header-profile .dropdown-menu .dropdown-header .title {
  color: #0fc;
  font-weight: 300;
  display: flex;
  align-items: center;
  cursor: default;
  user-select: none;
}
.header-profile .dropdown-menu .dropdown-header .title span {
  width: 180px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
  margin-right: 10px;
}
.header-profile .dropdown-menu .dropdown-header .title strong {
  font-weight: 600;
}
.header-profile .dropdown-menu .dropdown-footer {
  padding: 1rem 0 0;
}
.header-profile .dropdown-menu .dropdown-body {
  padding: 1rem 0;
  position: relative;
}
/* .header-profile .dropdown-menu .dropdown-body:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  height: 1px;
  width: 100%;
  background: rgb(0 255 204 / 30%);
} */
.header-profile .dropdown-menu .dropdown-body a {
  color: #eee;
  text-decoration: none;
}
.header-profile .dropdown-menu .dropdown-body a i {
  height: 35px;
  width: 35px;
  display: inline-block;
  font-size: 18px;
  background: #0c1a26;
  color: #eee;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  border-radius: 4px;
  margin-right: 15px;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.header-profile .dropdown-menu .dropdown-body a:hover i {
  background: #0fc;
  color: #030f1b;
}
.header-profile .dropdown-menu .btn {
  display: block;
}
.header-profile .dropdown-menu a:not(.btn) {
  padding: 0.3125rem 0;
  font-size: 1rem;
  display: flex;
  align-items: center;
  color: #fff !important;
}
.carousel-indicators [data-bs-target] {
  width: 160px;
  height: 2px;
  background-color: #132741;
  opacity: 1;
  margin-left: 0;
  margin-right: 0;
  position: relative;
  text-indent: unset;
}
.carousel-indicators {
  counter-reset: my-sec-counter;
  bottom: -60px;
}
.carousel-indicators .active {
  background-color: #747474;
}
.carousel-indicators button + button {
  margin-left: 30px !important;
}
.carousel-indicators button::after {
  counter-increment: my-sec-counter;
  content: "0" counter(my-sec-counter);
  position: absolute;
  font-size: 14px;
  font-family: "ClashDisplay";
  color: #132741;
  right: 0;
  top: 5px;
}
.carousel-control-next,
.carousel-control-prev {
  opacity: 0.5 !important;
}
.carousel-control-next:hover,
.carousel-control-prev:hover {
  opacity: 1 !important;
}
.carousel-indicators button.active::after {
  color: #fff;
}
.carousel-control-next-icon {
  background-image: url("./assets/images/next_icon.png");
}
.carousel-control-prev-icon {
  background-image: url("./assets/images/prev_icon.png");
}
/* .carousel-item {
  height: 100vh;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center;
} */
.carousel-item img {
  height: 100vh;
  object-fit: cover;
}
.banner_img {
  text-align: center;
}
.banner_img img {
  border-radius: 50px;
}
section {
  padding: 80px 0;
}
.collection_section {
  background: url("./assets/images/collections_bg.png") no-repeat center top
    150px;
  background-size: 100%;
}
.collections_table_div {
  background: rgb(2 12 21 / 85%);
  border: 1px solid #85a2b9;
  border-radius: 20px;
  padding: 30px;
}
.table>:not(caption)>*>* {
  background-color: transparent;
  
}
.table_collection_img {
  width: 65px;
  height: 65px;
  border-radius: 10px;
  margin-right: 15px;
}
table.collections_table {
  margin-bottom: 0;
}
table.collections_table thead th {
  color: #ff437f;
  font-size: 18px;
  font-family: "ClashDisplay";
  font-weight: 500;
  border-bottom: none;
  white-space: nowrap;
  padding: 10px 60px;
}
table.collections_table thead th:nth-child(1) {
  color: #fff;
}
table.collections_table tbody td {
  vertical-align: middle;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  border-bottom: 1px solid #85a2b9;
  white-space: nowrap;
  padding: 10px 60px;
}
table.collections_table tbody tr:last-child td {
  border-bottom: none;
}
.text_green {
  color: #00ff78 !important;
}
.text_red {
  color: #ff2929 !important;
}
table.collections_table tbody td a {
  color: #ffffff;
}
table.collections_table tbody td:last-child {
  color: #00ffcc;
  cursor: pointer;
}
.collections_table_sorting {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 30px;
}
.collections_table_sorting .input-group {
  width: 350px;
  margin-right: 30px;
}
.collections_table_sorting .input-group input {
  background: #020c15;
  border: 1px solid #85a2b9;
  box-shadow: none;
  color: #fff;
}
.collections_table_sorting .input-group .btn {
  background: #020c15;
  border: 1px solid #85a2b9;
}
select.form-select {
  background: #020c15 url("./assets/images/dropdown_icon.png") no-repeat right
    0.75rem center;
  background-size: 12px;
  border: 1px solid #85a2b9 !important;
  box-shadow: none !important;
  color: #fff;
}
.collections_table_sorting select.form-select {
  width: 220px;
}
.main_title {
  font-family: "Photograph Signature";
  font-size: 125px;
  color: #fff;
  margin-bottom: 30px;
}
.inner_para {
  font-size: 18px;
  font-weight: 400;
  color: #fff;
}
.benefits_section {
  background: url("./assets/images/benefits_collections.png") no-repeat center;
  background-size: 100%;
  z-index: 1;
  position: relative;
}
.benefits_panel {
  position: relative;
  display: flex;
}
/* .benefits_panel::after {
  content: "Treasures of the Seas";
  font-family: "Photograph Signature";
  font-size: 125px;
  color: #fff;
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  top: 34%;
  max-width: 50%;
  margin: 0 auto;
} */
.benefits_middle {
  z-index: -1;
}
.benefits_left,
.benefits_right {
  width: 27%;
}
.benefits_panel ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.benefits_panel ul li {
  font-size: 18px;
  margin-bottom: 15px;
}
.benefits_left ul {
  margin-top: 50px;
}
.benefits_left ul li {
  text-align: right;
  position: relative;
  margin-right: -50px;
}
.benefits_left ul li::before,
.benefits_right ul li::before {
  content: "";
  background: #ff437f;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  position: absolute;
}
.benefits_left ul li::before {
  right: -38px;
  top: 8px;
}
.benefits_right {
  margin-top: 25%;
}
.benefits_right ul {
  margin-top: 50px;
}
.benefits_right ul li {
  position: relative;
  margin-left: -50px;
}
.benefits_right ul li::before {
  left: -35px;
  top: 8px;
}
.roadmap_section {
  background: url("./assets/images/roadmap_bg.png") no-repeat center;
  background-size: 100%;
  position: relative;
}
.roadmap_section::before {
  content: "Voyage To Underworld";
  font-family: "Photograph Signature";
  font-size: 125px;
  color: #fff;
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  top: 50%;
  z-index: 10;
}
.roadmap_section p.inner_para {
  max-width: 60%;
  margin: -80px auto 0;
}
.roadmap_shipimg {
  padding-top: 120px;
}
.roadmap_list {
  display: flex;
  text-align: left;
  position: relative;
}
.roadmap_list h2 {
  font-size: 22px;
  color: #00ffcc;
  font-family: "ClashDisplay";
}
.roadmap_list p {
  font-size: 18px;
  color: #fff;
  margin-bottom: 0;
  max-width: 60%;
}
.roadmap_list_single {
  position: absolute;
}
.roadmap_list_single:nth-child(1) {
  top: 120px;
  left: 180px;
}
.roadmap_list_single:nth-child(2) {
  top: 0;
  left: 30%;
}
.roadmap_list_single:nth-child(3) {
  right: 150px;
  top: 0;
}
.roadmap_list_single:nth-child(4) {
  right: 0;
  top: 120px;
}
.roadmap_list_single::before {
  content: "";
  background: url("./assets/images/roadmap_line.png") no-repeat;
  width: 102px;
  height: 72px;
  position: absolute;
  z-index: 1;
}
.roadmap_list_single:nth-child(1)::before {
  left: 210px;
  top: 80px;
}
.roadmap_list_single:nth-child(2)::before {
  bottom: -101px;
  left: 122px;
  transform: rotate(25deg);
}
.roadmap_list_single:nth-child(3)::before {
  bottom: -98px;
  left: -48px;
  transform: rotate(85deg);
}
.roadmap_list_single:nth-child(4)::before {
  left: -98px;
  top: 37px;
  transform: rotate(94deg);
}
.faq_section {
  background: url("./assets/images/faq_bg.png") no-repeat center;
  background-size: 100%;
}
.faq_section p.inner_para {
  max-width: 50%;
  margin: 0 auto;
}
.faq_accordion {
  margin-top: 50px;
}
.faq_accordion .accordion-item {
  background: transparent;
}
.faq_accordion .accordion-button {
  background: transparent;
  border: none;
  box-shadow: none;
  color: #fff;
  font-size: 18px;
}
.faq_accordion .accordion-collapse.collapse {
  background: transparent;
}
.faq_accordion .accordion-item {
  margin-bottom: 30px;
  border: 1px solid #5f8187;
  border-radius: 100px;
  overflow: hidden;
  padding: 10px 50px;
}
.faq_accordion .accordion-item.active {
  background: rgb(15 42 50 / 75%);
  border: 1px solid rgb(15 42 50 / 75%);
}
.faq_accordion .accordion-body {
  color: #fff;
  font-size: 18px;
  padding-right: 120px;
}
.faq_accordion .accordion-button:not(.collapsed) {
  color: #ff437f;
}
.faq_accordion .accordion-button::after {
  background: url("./assets/images/plus_icon.png") no-repeat;
  width: 12px;
  height: 12px;
}
.faq_accordion .accordion-button:not(.collapsed)::after {
  background: url("./assets/images/minus_icon.png") no-repeat;
}
.footer {
  background-color: transparent;
  padding: 30px 0 20px;
}
.footer_panel {
  text-align: center;
}
.footer_panel_left p {
  margin-bottom: 0;
  font-size: 14px;
}
.footer_panel_left p a {
  color: #00ffcc;
  font-size: 14px;
}
.footer_panel_left a {
  font-size: 18px;
  color: #feba31;
  white-space: normal;
  word-break: break-all;
}
.footer_social_links {
  display: flex;
  justify-content: center;
  list-style: none;
  margin: 0;
  padding: 0;
  margin-bottom: 15px;
}
.footer_social_links li {
  margin: 0 0 0 15px;
}
.footer_social_links li a {
  width: 36px;
  height: 36px;
  background: #103c4c;
  border: 1px solid #103c4c;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #020c15;
  font-size: 18px;
  text-decoration: none;
  transition: 0.2s all;
}
.footer_social_links li a:hover {
  background: #7b989a;
  border: 1px solid #7b989a;
  color: #020c15;
}
.footer_menu_links {
  display: flex;
  justify-content: center;
  padding: 0;
  list-style: none;
  margin-bottom: 15px;
}
.footer_menu_links li {
  position: relative;
}
.footer_menu_links li + li {
  margin-left: 30px;
}
.footer_menu_links li::after {
  content: "";
  background: #fff;
  width: 1px;
  height: 12px;
  position: absolute;
  right: -15px;
  top: 7px;
}
.footer_menu_links li:last-child:after {
  display: none;
}
.footer_menu_links li a {
  color: #fff;
  font-size: 14px;
  transition: 0.2s all;
}
.footer_menu_links li a:hover {
  color: #00ffcc;
}
.primary_btn {
  position: relative;
  font-family: "ClashDisplay";
  overflow: hidden;
  display: flex;
  align-items: center;
  padding: 3px 18px;
  font-size: 20px;
  line-height: 1.715;
  border: 2px solid #00ffcc;
  background: #00ffcc;
  color: #030f1b;
  border-radius: 50px;
  text-transform: uppercase;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
  cursor: pointer;
  vertical-align: middle;
  user-select: none;
  transition: 0.2s all ease-in-out;
  text-decoration: none !important;
  z-index: 1;
  margin: 30px auto 0;
}
.primary_btn:hover {
  border: 2px solid #051424;
  background: #051424;
  color: #00ffcc;
}
.navbar_right .primary_btn {
  margin: 0;
}
.navbar_right button {
  margin-right: 15px !important;
}
.inner_para a {
  word-break: break-all;
}
.primary_btn.address_btn {
  width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}
/* Connect Wallet */
.primary_modal .modal-content {
  background-color: #020c15;
}
.primary_modal .modal-header {
  border-bottom: 1px solid #121226;
  padding: 20px 24px 15px;
}
.connect_wallet_button {
  border: 2px solid rgb(0 255 204 / 20%);
  background-color: transparent;
  color: #111111;
  border-radius: 23px;
  padding: 0 24px;
  outline: 0;
  line-height: 46px;
  height: 52px;
  font-size: 18px;
  width: 100%;
  display: flex;
  align-items: center;
  transition: 0.2s all;
}
.connect_wallet_button:hover {
  border-color: rgb(0 255 204 / 100%);
}
.primary_modal .modal-header h5 {
  font-size: 24px;
  font-weight: 500;
  margin: 0;
  color: #fff;
}
.connect_wallet_button img {
  margin-right: 15px;
}
.connect_wallet_button span {
  color: #fff;
  font-size: 22px;
  font-weight: 400;
}
.btn-close {
  width: 32px;
  height: 32px;
  color: #000;
  border: 0;
  background: transparent url("../src/assets/images/close_icon.png") no-repeat;
  box-shadow: none !important;
}
.primary_modal a {
  text-decoration: none;
}
.d_d_none {
  display: none !important;
}

/* Collection Page Css Starts */
.inner_wrapper {
  position: relative;
  min-height: 100vh;
  padding-bottom: 100px;
}
.inner_wrapper::after {
  content: "";
  background: url("./assets/images/inner_bottom_bg.png") no-repeat bottom center;
  /* background-size: cover; */
  width: 100%;
  height: 951px;
  position: absolute;
  bottom: -80px;
  z-index: -1;
}
.collection_banner {
  /* background: url("./assets/images/collection-banner.png") no-repeat center; */
  background-size: cover;
  width: 100%;
  height: 400px;
  position: relative;
}
.collection_profile_wrap {
  width: 150px;
  height: 150px;
  position: absolute;
  bottom: -80px;
}
.ant-table {
  background: transparent !important;
 
}
th.ant-table-cell{
  background: transparent !important;
  color: #ff437f !important;
}
.ant-table-cell{
  background: transparent !important;
  color: #fff !important;
}

.collection_profile_wrap img {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 10px;
}
.collections_details_wrap {
  margin-top: 120px;
}
.collection_details_grid {
  grid-gap: 15px;
  background-clip: padding-box !important;
  border: 3px solid transparent;
  border-radius: 5px;
  display: grid;
  row-gap: 30px;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  position: relative;
  margin-top: 30px;
}
.collection_details_grid div {
  background: #0c252e;
  border-radius: 10px;
  cursor: default;
  padding: 20px 10px;
  position: relative;
  text-align: center;
  transition-duration: 0.5s;
  transition-property: box-shadow;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  width: 150px;
}
.collection_details_grid div h3 {
  color: #ff437f;
  font-size: 24px;
  font-weight: 700;
  line-height: 24px;
  margin: 0;
}
.collection_details_grid div p {
  color: #fff;
  font-size: 16px;
  margin: 0;
}
.collection_mint_box_details {
  margin: 30px 0;
}
.collection_mint_box {
  border: 2px solid #85a2b9;
  border-radius: 25px;
  padding: 30px;
  max-width: 80%;
  margin-left: auto;
  margin-right: 15px;
}
.collection_mint_box h2 {
  font-size: 26px;
  font-family: "ClashDisplay";
  color: #46fffc;
}
.collection_mint_box_details div {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}
.collection_mint_box_details div label {
  font-size: 18px;
  font-weight: 500;
}
.collection_mint_box_details div label:nth-child(1) {
  width: 56%;
}
.collection_mint_box_details div span {
  margin-right: 30px;
}
.collection_mint_box input.form-control {
  border: 1px solid #292b2d;
  background: #000000;
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  padding: 12px 10px;
  border-radius: 10px;
}
.form-control {
  box-shadow: none !important;
}
.collection_info p {
  font-size: 18px;
}
.collection_info h2 {
  color: #ff437f;
  font-size: 36px;
  font-weight: 700;
  margin: 30px 0 20px;
}
.collection_info .input_grp {
  position: relative;
}
.collection_info .input_grp input {
  border: 1px solid #0c273f;
  border-radius: 50px;
  background: #01080e;
  color: #aaacad;
  padding: 18px 70px 18px 30px;
}
span.copy_btn {
  position: absolute;
  top: 18px;
  right: 30px;
  cursor: pointer;
}
.collection_info_table {
  margin-top: 50px;
}
.collection_info_table h1 {
  font-size: 41px;  
  margin-bottom: 30px;
  font-weight: 600;
  letter-spacing: 1px;
  border-radius: 10px;
  border: 1px solid #061728;
  background: rgba(2, 10, 19, 0.75);
  padding: 15px;
  text-align: center;
}
.collection_info_table h1 span {
  color: #ff437f;
}
.collection_info_table .collections_table_div {
  border: 1px solid #061728;
}
.collection_info_table table.collections_table tbody td {
  border-bottom: 1px solid #061728;
}
.collection_info_table table.collections_table tbody td {  
  padding: 25px 60px;
}
.collection_info_table table.collections_table tbody td:first-child {
  padding: 25px 20px;
}
.collection_info_table table.collections_table thead th:first-child {
  padding: 25px 20px;
}
.owned_nft_list {
  display: flex;
  align-items: flex-start;
  padding: 30px;
  border: 1px solid #061728;
  background: rgb(2 11 20 / 75%);
  border-radius: 20px;
  margin-bottom: 40px;
}
.nft_img_wrap {
  width: 300px;
  height: 350px;
  margin-left: 30px;
}
.nft_img_wrap img {
  object-fit: cover;
  width: 300px;
  height: 350px;
  border-radius: 10px;
}
.nft_description {
  margin-left: 30px;
  width: calc(100% - 400px);
}
.nft_description h2 {
  font-size: 36px;
  font-weight: 700;
  color: #ff437f;
}
.nft_description h3 {
  font-size: 20px;
  font-weight: 500;
  color: #29fffc;
}
.nft_details {
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
}
.nft_details > div {
  width: 50%;
  margin-bottom: 15px;
}
.nft_details > div label {
  font-size: 18px;
  font-weight: 500;
}
.nft_details > div label:first-child {
  width: 50%;
}
button.secondary_btn {
  position: relative;
  font-family: "ClashDisplay";
  overflow: hidden;
  display: flex;
  align-items: center;
  padding: 3px 18px;
  font-size: 20px;
  line-height: 1.715;
  border: 2px solid #008da5;
  background: #008da5;
  color: #ffffff;
  border-radius: 50px;
  text-transform: capitalize;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
  cursor: pointer;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  transition: 0.2s all ease-in-out;
  text-decoration: none !important;
  z-index: 1;
}
button.secondary_btn:hover {
  border: 2px solid #ffffff;
  background: #ffffff;
  color: #008da5;
}
.owned_nft_wrap {
  margin-top: 50px;
}
.form-checkbox-input {
  background-color: transparent;
  border: 2px solid #85a2b9;
  border-radius: 100% !important;
  width: 27px;
  height: 27px;
  box-shadow: none !important;
}
.form-checkbox-input:checked[type=checkbox] {
  background-image: url("./assets/images/checkbox_checked.png");
  background-repeat: no-repeat;
  background-position: center;
}
.form-checkbox-input:checked {
  background: transparent;
  border-color: #85a2b9 !important;
}
.form-checkbox-input:focus {
  border-color: #85a2b9 !important;
}
.owned_nft_wrap .primary_btn {
  text-transform: capitalize;
  font-size: 24px;
  padding: 3px 30px;
}
.inner_heading {
  font-size: 26px;
  font-family: "ClashDisplay";
  margin: 30px 0 15px;
}
.approved_market_div {
  border: 1px solid #061728;
  border-radius: 20px;
}
.approved_market_table {
  margin-bottom: 0;
}
.approved_market_table thead tr th {
  font-family: "ClashDisplay";
  font-size: 18px;
  font-weight: 500;
  color: #ff437f;
  padding: 15px 50px;
  border: none;
  white-space: nowrap;
}
/* .approved_market_table thead tr th:first-child {
  color: #ff437f;
} */
.approved_market_table tbody tr td {
  font-size: 18px;
  color: #fff;
  padding: 15px 50px;
  border-bottom: 0;
  border-top: 2px solid #061728;
  white-space: nowrap;
}
.approved_market_table tbody tr:nth-child(odd) {
  background: #030f1b;
}
.approved_market_table tbody tr:nth-child(even) {
  background: #041220;
}
.approved_market_table tbody tr td:nth-child(3),
.approved_market_table thead tr th:nth-child(3) {
  background: rgb(12 37 46 / 18%);
}
.approved_market_table tbody tr td:nth-child(3) a {
  color: #29fffc;
}
.approved_market_table tbody tr td:nth-child(4) a {
  color: #fff;
}
.approved_market_table tbody tr td:nth-child(4) img {
  margin-left: 30px;
  cursor: pointer;
}
.claimable_share {
  border: 1px solid #061728;
  background: rgba(2, 10, 19, 0.75);
  padding: 20px 30px;
  display: flex;
  border-radius: 10px;
  align-items: center;
  justify-content: space-between;
}
.claimable_share button.primary_btn {
  margin: 0;
  text-transform: capitalize;
  font-size: 24px;
  padding: 3px 30px;
  white-space: normal;
}
.claimable_share h2 {
  margin: 0;
  font-size: 24px;
  font-family: "ClashDisplay";
}
.claimable_share h2 span {
  color: #ff437f;
}


/* Collection Page Css Ends */

/* Media Queries */
@media screen and (max-width: 1399px) {
  .benefits_left ul li {
    margin-right: -46px;
  }
  .benefits_right ul li {
    margin-left: -46px;
  }
  .roadmap_list_single:nth-child(1) {
    top: 112px;
    left: 95px;
  }
  .roadmap_list_single:nth-child(2) {
    top: 10px;
    left: 27%;
  }
  .roadmap_list_single:nth-child(3) {
    right: 60px;
    top: 5px;
  }
  .roadmap_list_single:nth-child(4) {
    right: -42px;
    top: 110px;
  }
  .roadmap_list_single:nth-child(4)::before {
    left: -89px;
    top: 107px;
    transform: rotate(85deg);
  }
  .nft_details > div {
    width: 100%;
  }
}
@media screen and (max-width: 1199px) {
  .benefits_panel::after,
  .roadmap_section::before,
  .main_title {
    font-size: 92px;
  }
  .benefits_right ul li {
    margin-left: -38px;
  }
  .benefits_right ul {
    margin-top: 18px;
  }
  .benefits_left ul li {
    margin-right: -39px;
  }
  .roadmap_list_single {
    position: relative;
    top: unset !important;
    left: unset !important;
    right: unset !important;
    width: 50%;
    margin-bottom: 30px;
    text-align: center;
  }
  .roadmap_list_single::before {
    display: none;
  }
  .roadmap_shipimg {
    padding-top: 0;
  }
  .roadmap_list {
    flex-wrap: wrap;
    margin-bottom: -300px;
    margin-top: 190px;
    z-index: 10;
  }
  .roadmap_list p {
    margin: 0 auto;
    max-width: 80%;
  }
  .faq_section p.inner_para {
    max-width: 100%;
  }
  .collection_section,
  .benefits_section,
  .roadmap_section,
  .faq_section {
    background-size: auto;
  }
  .roadmap_section::before {
    top: 50px;
  }
  .primary_btn {
    font-size: 18px;
  }
  .carousel-item img {
    height: 85vh;
  }
}
@media screen and (max-width: 991px) {
  .footer_panel {
    display: block;
    text-align: center;
  }
  .footer_social_links {
    justify-content: center;
    margin-top: 15px;
  }
  .offcanvas {
    background-color: #020c15 !important;
    box-shadow: 0 0 10px 5px rgb(4 4 4 / 50%);
  }
  .navbar-toggler:focus {
    box-shadow: none;
  }
  .navbar_right {
    margin-top: 15px;
    display: block;
  }
  .navbar_right button {
    margin-top: 15px !important;
  }
  .benefits_panel {
    flex-wrap: wrap;
  }
  .benefits_middle {
    display: none;
  }
  .benefits_left,
  .benefits_right {
    width: 100%;
  }
  .benefits_left ul li {
    text-align: left;
    margin-right: 0;
  }
  .benefits_right {
    margin-top: 0;
  }
  .benefits_right ul li {
    margin-left: 0;
    text-align: left;
  }
  /* .benefits_left ul li::before,
  .benefits_right ul li::before {
    display: none;
  } */
  .benefits_right ul {
    margin-top: 0;
  }
  .benefits_panel::after {
    top: -40px;
    max-width: 100%;
  }
  .benefits_left ul li::before {
    right: unset;
    top: 8px;
    left: -35px;
  }
  .benefits_left ul li,
  .benefits_right ul li {
    margin-left: 50px;
  }
  /* .benefits_section::before {
    content: "";
    background: url('./assets/images/dolphin.png') no-repeat center;
    background-size: 70%;
    z-index: -1;
    width: 584px;
    height: 589px;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
  } */
  /* .benefits_section {
    min-height: 80vh;
  } */
  .carousel-item img {
    height: 65vh;
  }
  .d_d_none {
    display: block !important;
  }
  .benefits_left ul {
    margin-top: 30px;
  }
  .collection_mint_box {
    max-width: 100%;
    margin-right: 0;
    margin-top: 30px;
  }
  .owned_nft_list {    
    flex-wrap: wrap;
  }
  .nft_description {
    margin-left: 60px;
    width: 100%;
    margin-top: 30px;
  }
  .primary_btn.address_btn {
    width: 100%;
  }
  .claimable_share {
    flex-wrap: wrap;
    justify-content: center;
  }
  .claimable_share h2 {
    margin-bottom: 15px;
    text-align: center;
  }
}
@media screen and (max-width: 767px) {
  .footer_panel_left a {
    font-size: 20px;
  }
  button.logout_btn {
    margin-bottom: 15px;
  }
  .benefits_panel::after,
  .roadmap_section::before,
  .main_title {
    font-size: 72px;
  }
  .roadmap_section p.inner_para {
    max-width: 100%;
  }
  .benefits_panel ul li,
  .inner_para,
  .faq_accordion .accordion-body,
  .faq_accordion .accordion-button,
  .roadmap_list p {
    font-size: 16px;
  }
  .roadmap_list_single {
    width: 100%;
  }
  .roadmap_list p {
    max-width: 100%;
  }
  .carousel-item img {
    height: 48vh;
  }
  img.brand_logo {
    width: 80px;
  }
}
@media screen and (max-width: 575px) {
  .main_navbar .container-fluid {
    display: block;
    text-align: center;
  }
  .primary_btn {
    padding: 12px 25px;
    font-size: 16px;
  }
  .benefits_section::before {
    width: 100%;
  }
  .benefits_left {
    margin-top: 30px;
  }
  .faq_accordion .accordion-body {
    padding-right: 0;
  }
  .benefits_left ul li,
  .benefits_right ul li {
    margin-left: 60px;
  }
  .benefits_panel::after,
  .roadmap_section::before,
  .main_title {
    font-size: 60px;
  }
  .roadmap_section p.inner_para {
    margin: 0 auto 0;
  }
  .roadmap_list {
    margin-bottom: -200px;
  }
  .footer_social_links {
    flex-wrap: wrap;
  }
  .footer_social_links li {
    margin: 0 0 15px 15px;
  }
  .footer_menu_links li a {
    font-size: 13px;
  }
  .collections_table_sorting {
    display: block;
  }
  .collections_table_sorting .input-group {
    width: 100%;
    margin-bottom: 20px;
  }
  .collections_table_sorting select.form-select {
    width: 100%;
  }
  .carousel-control-next-icon,
  .carousel-control-prev-icon {
    width: 22px;
    height: 22px;
  }
  .benefits_left ul {
    margin-top: 0;
  }
  .collection_details_grid {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .owned_nft_wrap .primary_btn {
    white-space: normal;
  }
  .nft_description {
    margin-left: 0;
  }
  .nft_details > div label:first-child {
    width: 100%;
  }
  .connect_wallet_button span {
    font-size: 16px;    
  }
  .connect_wallet_button img {
    margin-right: 10px;
  }
  .collection_banner {
    height: 180px;
  }
}
::placeholder {
  color: #f9f9f9 !important;
}
li.ant-pagination-item {
  color: white;
}
li.ant-pagination-item-active {
  color: #3c3c3c;
}
.rdt_TableHeadRow {
  background: transparent !important;
  color: #fff !important;
}
.rdt_TableRow {
  background: transparent !important;
  color: #ffffff !important;
}
.rdt_TableRow {
  background: transparent !important;
  color: #ffffff !important;
  padding: 16px;
}
.rdt_TableHeadRow {

  border-bottom: 2px solid;
}
.rdt_TableRow {
 
  border-bottom: 1px solid !important;
}

.rdt_TableCol_Sortable {
  border-bottom: none;
  color: #ff437f;
  font-family: ClashDisplay !important;
  font-size: 16px;
  font-weight: 500;
}
.rdt_TableCell >div>a {
  color: #fff;
  font-size: 16px !important;
}
.rdt_TableCell >div>span {
  color: #fff;
  font-size: 16px !important;
}
.rdt_Pagination {
  background: transparent !important;
  color: #ffffff !important;
}


.rdt_Pagination select {
  color: #ffffff !important;
}
.rdt_Pagination span {
  color: #fff !important;
}
.rdt_Pagination svg {
  color: #fff !important;
}
table.table.collections_table svg {
  fill: #fff !important;
}
.rdt_TableCell {
  padding-left: 3px !important;
}
.loading {
  position: fixed;
  top: 50%;
  left: 50%;
  fill:rgb(0, 16, 247) !important;
  margin-left: -32px;
  margin-top: -32px;
  z-index: 99999;
}
.loading::before{
  content: "";
  position: fixed;
  left: 0;
  top:0;
  width: 100%;
  height: 100vh;
  background: rgba(245, 242, 242, 0.25);
  z-index: 1500;
  overflow: hidden;
}